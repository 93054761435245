import EditableBadge from 'components/EditableBadge';
import PrefixListInput from 'components/PrefixListInput';
import { useOutsideClick } from 'hooks/useOutsideClick';
import React, { KeyboardEvent, useRef, useState } from 'react';

interface TextPromptsProps {
  prompts: string[];
  setPrompts: (prompts: string[]) => void;
  setIsEditingPrompts: (isEditingPrompts: boolean) => void;
  onPromptClick?: any;
}
const TextPrompts: React.FC<TextPromptsProps> = ({
  prompts,
  setPrompts,
  setIsEditingPrompts,
  onPromptClick,
}: TextPromptsProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [isLastPromptFocused, setIsLastPromptFocused] =
    useState<boolean>(false);
  const promptsContainerRef = useRef<HTMLDivElement>(null);

  const handleAddPrompt = (value: string) => {
    setPrompts([...prompts, value]);
    setIsEditingPrompts(true);
    setInputValue('');
  };

  const handleRemovePrompt = (idx: number) => {
    setIsEditingPrompts(true);
    setPrompts(prompts.filter((_, subIdx) => idx !== subIdx));
    onPromptClick?.(idx);

    if (isLastPromptFocused) {
      setIsLastPromptFocused(false);
    }
  };

  const validatePrompt = (prompt: string) =>
    Boolean(prompt.length && !prompts.includes(prompt));

  const handleEditPrompt = (newValue: string, promptIdx: number) => {
    const trimmedValue = newValue.trim().replace(/\s+/g, ' ');
    const isInputValueValid = validatePrompt(trimmedValue);

    if (isInputValueValid) {
      const newPrompts = prompts.map((prompt, subIdx) =>
        subIdx === promptIdx ? trimmedValue : prompt,
      );
      setIsEditingPrompts(true);
      setPrompts(newPrompts);
    }
  };

  const handleBackspaceKey = () => {
    if (isLastPromptFocused) {
      handleRemovePrompt(prompts.length - 1);
    } else {
      setIsLastPromptFocused(true);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = e.currentTarget.value.trim().replace(/\s+/g, ' ');
    const isInputValueValid = validatePrompt(trimmedValue);

    if ((e.key === 'Enter' || e.key === ',') && isInputValueValid) {
      e.preventDefault();
      handleAddPrompt(trimmedValue);
    } else if (e.key === 'Backspace' && inputValue.length === 0) {
      handleBackspaceKey();
    } else {
      setIsLastPromptFocused(false);
    }
  };

  const handleOutsideClick = () => {
    setIsLastPromptFocused(false);
  };

  useOutsideClick(promptsContainerRef, handleOutsideClick);

  return (
    <div className="h-full w-full" ref={promptsContainerRef}>
      <PrefixListInput
        ref={promptsContainerRef}
        placeholder="Start typing to add a text prompt..."
        label="Text prompts"
        value={inputValue}
        onChange={setInputValue}
        prefixList={prompts.map((prompt: any, key: number) => (
          <EditableBadge
            classNames={
              key === prompts.length - 1 && isLastPromptFocused
                ? 'bg-emerald-200'
                : ''
            }
            inputValidation={validatePrompt}
            key={prompt}
            label={prompt}
            onClick={() => handleRemovePrompt(key)}
            onSubmitBadgeEdit={(newLabel) => handleEditPrompt(newLabel, key)}
          />
        ))}
        showPrefixCount
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

TextPrompts.defaultProps = {
  onPromptClick: undefined,
};

export default TextPrompts;
