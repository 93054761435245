import React from 'react';
import { Column, ColumnDisplayType, ColumnStyle } from 'api/generated';
import classNames from 'classnames';
import ActionsMenu from 'components/ActionsMenu';
import { BadgeList } from 'components/BadgeList';
import { ImageSetPreview } from 'components/ImageSetPreview';
import ImagePreview from 'components/Table/TableBodyRow/ImagePreview';
import Pills from 'components/Table/TableBodyRow/Pills';
import SecretValue from 'components/Table/TableBodyRow/SecretValue';
import Status from 'components/Table/TableBodyRow/Status';
import Timestamp from 'components/Table/TableBodyRow/Timestamp';
import { TableAction, TableRow } from 'components/Table/types';
import { Link } from 'react-router-dom';
import { formatPath } from 'utils/NavigationUtils';

interface TableBodyRowProps<T extends TableRow> {
  row: T;
  idKey: string;
  columns: Column[];
  hiddenColumns: Set<string> | undefined;
  actions: TableAction<T>[] | undefined;
  getStatusClassName: (status: string) => string;
  onClickImagePreview?: (data: TableRow) => void;
  className?: string;
}

const TableBodyRow = function TableBodyRow<T extends TableRow>({
  row,
  idKey,
  columns,
  hiddenColumns,
  actions,
  getStatusClassName,
  onClickImagePreview,
  className,
}: TableBodyRowProps<T>) {
  const visibleActions = actions?.filter(
    (action) => !action.dynamicHide?.(row),
  );

  return (
    <tr
      key={row?.data[idKey]}
      className={className}
      data-cy={`table-row-${row.row}`}
    >
      {columns
        ?.filter((col) => !hiddenColumns?.has(col.key))
        .map((col, idx) => {
          let cellValue;
          if (col.keyPath) {
            cellValue = row.data;
            col.keyPath.forEach((key) => {
              cellValue = cellValue[key];
            });
          } else {
            cellValue = row.data[col.key];
          }

          return (
            <td
              data-cy={`${row?.data[idKey]}-${col.key}`}
              key={col.key}
              className={classNames(
                col.key !== 'row' &&
                  'truncate whitespace-nowrap text-sm font-medium text-ellipsis max-w-xs ',
                {
                  'px-4 py-3':
                    col.displayType !== ColumnDisplayType.Image &&
                    col.key !== 'row',
                  'text-gray-900':
                    col.style === ColumnStyle.Primary &&
                    col.displayType !== ColumnDisplayType.Action,
                  'text-gray-500':
                    col.style !== ColumnStyle.Primary &&
                    col.displayType !== ColumnDisplayType.Action,
                  'text-right':
                    col.displayType === ColumnDisplayType.Action &&
                    idx === (columns?.length || 0) - 1,
                  'overflow-hidden':
                    col.displayType !== ColumnDisplayType.Action,
                  'font-medium': col.displayType === ColumnDisplayType.Action,
                  'overflow-visible':
                    col.displayType === ColumnDisplayType.Image,
                  'sr-only': col.key === 'row',
                },
              )}
            >
              {col.displayType === ColumnDisplayType.Action && col.to ? (
                <Link
                  to={formatPath(col.to, row.data!)}
                  className="text-blue-500 hover:text-blue-600"
                >
                  {cellValue || col.label}
                </Link>
              ) : undefined}
              {col.displayType === ColumnDisplayType.Status ? (
                <Status
                  status={cellValue}
                  className={getStatusClassName(cellValue)}
                />
              ) : undefined}
              {col.displayType === ColumnDisplayType.RelativeTimestamp ? (
                <Timestamp datetime={cellValue} />
              ) : undefined}
              {col.displayType === ColumnDisplayType.Secret ? (
                <SecretValue value={cellValue} />
              ) : undefined}
              {col.displayType === ColumnDisplayType.Image && (
                <ImagePreview
                  value={cellValue}
                  row={row}
                  onClickImagePreview={onClickImagePreview}
                />
              )}
              {col.displayType === ColumnDisplayType.Pill && (
                <Pills value={cellValue} />
              )}
              {col.displayType === ColumnDisplayType.BadgeList && (
                <BadgeList elements={cellValue} maxElementsToShow={4} />
              )}
              {col.displayType === ColumnDisplayType.ImageSetPreview && (
                <ImageSetPreview
                  images={cellValue}
                  maxImagesToShow={3}
                  className="h-8"
                />
              )}

              {(col.displayType === ColumnDisplayType.Default ||
                !col.displayType) &&
                (cellValue === undefined || cellValue === null ? (
                  <span className="text-gray-400">{col.emptyText}</span>
                ) : (
                  `${cellValue}`
                ))}
            </td>
          );
        })}
      {visibleActions && Boolean(visibleActions.length) && (
        <td className="whitespace-nowrap text-sm font-medium text-ellipsis overflow-visible px-4 py-3 text-right shrink">
          <ActionsMenu
            dataTestAccessor={row?.data[idKey]}
            actions={visibleActions.map((action) => ({
              ...action,
              action: () => action?.action?.(row),
            }))}
            insideScrollableContainer
          />
        </td>
      )}
    </tr>
  );
};

TableBodyRow.defaultProps = {
  onClickImagePreview: undefined,
  className: undefined,
};

export default TableBodyRow;
