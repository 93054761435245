import { AssetResponse, SimilaritySearchResponse } from 'api/generated';
import EmptyStateMessage from 'components/EmptyStateMessage';
import ImageGrid from 'components/ImageGrid';
import { UnifiedAssetResponse } from 'types/image';
import InteractiveLabelGrid from 'components/InteractiveLabelGrid';
import { ScrollToTop } from 'components/ScrollToTop';
import { AssetTypeOption } from 'components/TextSearch';
import SearchResultsHeader from 'components/TextSearch/SearchResultsHeader';
import ToggleImageGrid from 'components/ToggleImageGrid';
import React, { useMemo, JSX } from 'react';
import { InfiniteData } from 'react-query';
import ClipLoader from 'react-spinners/ClipLoader';
import colors from 'tailwindcss/colors';

interface TextSearchResultsProps {
  results: InfiniteData<SimilaritySearchResponse>;
  isLoading: boolean;
  isRefetching: boolean;
  clearSearch: () => void;
  onClick?: (img: AssetResponse) => void;
  assetType: AssetTypeOption;
  labelResults?: (positive: string[], negative: string[]) => void;
  isLabelingResults?: boolean;
  hideClearSearch?: boolean;
  hideHeader?: boolean;
  hasToggleGridResults?: boolean;
  disabledResults?: AssetResponse[];
  disabledResultMessage?: string;
  tileFooter?: (img: UnifiedAssetResponse) => JSX.Element;
  dataTestId?: string;
}

const TextSearchResults: React.FC<TextSearchResultsProps> =
  function TextSearchResults({
    results,
    isLoading,
    isRefetching,
    clearSearch,
    onClick,
    assetType,
    labelResults,
    isLabelingResults,
    hideClearSearch,
    hideHeader,
    hasToggleGridResults,
    // Only work with regular and toggle image grids
    disabledResultMessage,
    disabledResults,
    tileFooter,
    dataTestId,
  }) {
    const images = useMemo(
      () =>
        isRefetching
          ? []
          : results?.pages.reduce(
              (acc: AssetResponse[], item: SimilaritySearchResponse) => [
                ...acc,
                ...item.data.map((i) => i.items[0]),
              ],
              [],
            ) || [],
      [results, isRefetching],
    );

    return (
      <div>
        {isRefetching && (
          <div className="w-full flex items-center justify-center h-[50vh]">
            <ClipLoader
              cssOverride={{ textAlign: 'center' }}
              color={colors.green['500']}
              loading
              size={40}
              speedMultiplier={0.75}
            />
          </div>
        )}
        {!isLoading && Boolean(images?.length) && (
          <>
            {!hideHeader && (
              <SearchResultsHeader
                clearSearch={clearSearch}
                searchResultsCount={images.length}
              />
            )}
            {labelResults && (
              <InteractiveLabelGrid
                images={images}
                cancel={clearSearch}
                addLabels={labelResults}
                isAddingLabels={Boolean(isLabelingResults)}
                dataTestId={dataTestId}
              />
            )}
            {hasToggleGridResults && (
              <ToggleImageGrid
                images={images}
                onAssetClick={onClick!}
                disabledTiles={disabledResults}
                disabledMessage={disabledResultMessage}
              />
            )}
            {!labelResults && !hasToggleGridResults && (
              <ImageGrid
                images={images}
                type="uniform"
                hasMore={false}
                onClick={onClick}
                disabledTiles={disabledResults}
                disabledMessage={disabledResultMessage}
                tileFooter={tileFooter}
                dataTestId={dataTestId}
              />
            )}
            <ScrollToTop />
          </>
        )}
        {!isLoading && !isRefetching && !images?.length && (
          <div className="text-center py-12 px-2 text-sm">
            <EmptyStateMessage>
              <p>No {assetType || 'images or videos'} found</p>
              {!hideClearSearch && (
                <button
                  type="button"
                  className="text-emerald-500 hover:text-blue-600 font-medium mt-4"
                  onClick={clearSearch}
                >
                  Clear Search
                </button>
              )}
            </EmptyStateMessage>
          </div>
        )}
        {/* Hack to not cut off the shadow on the search box */}
        {isRefetching && <div className="h-32" />}
      </div>
    );
  };

TextSearchResults.defaultProps = {
  labelResults: undefined,
  isLabelingResults: false,
  hideHeader: false,
  hideClearSearch: false,
  onClick: undefined,
  hasToggleGridResults: undefined,
  disabledResultMessage: undefined,
  disabledResults: undefined,
  tileFooter: undefined,
  dataTestId: undefined,
};

export default TextSearchResults;
