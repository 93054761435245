import React, { useCallback } from 'react';
import { EncoderNameEnum, KeyframeSamplingMethodEnum } from 'api/generated';
import Disclosure from 'components/Disclosure';
import Dropdown from 'components/Dropdown';
import RadioTabsGroup, {
  RadioTabsGroupOption,
} from 'components/RadioTabsGroup';
import TextInput from 'components/TextInput';

interface DatasetAdvancedSettingsProps {
  encoderOptions: { label: string; value: EncoderNameEnum }[];
  encoder: EncoderNameEnum;
  setEncoder: (encoder: EncoderNameEnum) => void;
  samplingMethodOptions: RadioTabsGroupOption<KeyframeSamplingMethodEnum>[];
  samplingMethod: RadioTabsGroupOption<KeyframeSamplingMethodEnum>;
  setSamplingMethod: (
    samplingMethod: RadioTabsGroupOption<KeyframeSamplingMethodEnum>,
  ) => void;
  setSamplingRate: (samplingRate: number | null) => void;
}

const DatasetAdvancedSettings: React.FC<DatasetAdvancedSettingsProps> =
  function DatasetAdvancedSettings({
    encoder,
    setEncoder,
    encoderOptions,
    samplingMethodOptions,
    samplingMethod,
    setSamplingMethod,
    setSamplingRate,
  }) {
    const validateSamplingRate = useCallback((value) => {
      const inRange = value >= 0 && value <= 60;

      return Promise.resolve({
        valid: {
          value: inRange,
          message: inRange
            ? undefined
            : 'Uniform sampling rate must be a value between 0 and 60 frames per second',
        },
        unique: { value: false },
      });
    }, []);

    return (
      <Disclosure title="ADVANCED DATASET SETTINGS">
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-lg font-medium text-gray-800">
              Which foundation model would you like to use to encode your
              dataset?
            </p>
            <p className="text-sm">
              Coactive offers a range of foundation embedding models, with
              different sizes and resources required. Here you can select the
              model best suited to your dataset.
            </p>
          </div>
          <div>
            <Dropdown
              label="Foundation model"
              options={encoderOptions}
              selected={encoder}
              onChange={setEncoder}
            />
          </div>
          <div>
            <p className="text-lg font-medium text-gray-800">
              What type of frame sampling would you like to use for video
              ingestion?
            </p>
            <p className="text-sm">
              Coactive&apos;s intelligent sampling chooses the most
              representative key frames for your videos so that more key frames
              are captured for more relevant portions of your video. If
              intelligent sampling wasn&apos;t providing the correct results, we
              recommend trying uniform sampling. Please note: changing the frame
              sampling will only affect videos.
            </p>
            <div className="mt-4 space-y-4">
              <RadioTabsGroup
                options={samplingMethodOptions}
                selected={samplingMethod}
                setSelected={setSamplingMethod}
              />
              {samplingMethod.value === KeyframeSamplingMethodEnum.Uniform && (
                <TextInput
                  containerClassName="w-64"
                  inputClassName="!w-28"
                  label="Sampling rate"
                  id="sampling-rate"
                  name="sampling rate"
                  placeholder="Example: 0.5"
                  onChange={(e) => setSamplingRate(Number(e.target.value))}
                  type="number"
                  suffix="frames per second"
                  readOnlySuffix
                  validation={validateSamplingRate}
                  defaultValue="2"
                />
              )}
            </div>
          </div>
        </div>
      </Disclosure>
    );
  };

export default DatasetAdvancedSettings;
